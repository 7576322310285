<template>
  <Layout>
    <template v-slot:content>
      <div
        class="h-auto md:h-full flex flex-col md:flex-col-reverse justify-between pb-2"
      >
        <div class="flex md:items-end p-4">
          <div class="w-1/2">
            <router-link
              to="/feed"
              class="w-10 p-2 md:w-52 md:pr-6 md:pl-4 py-2 rounded border text-gray-400 hover:bg-primary hover:text-white hover:border-transparent cursor-pointer flex items-center justify-between"
            >
              <svg
                class="fill-current w-5 h-5 md:mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="hidden md:block">{{ $t("back-to-feed") }}</span>
            </router-link>
          </div>
          <div class="w-1/2 flex justify-end">
            <button class="btn-primary flex items-center" @click="toggleLike">
              <svg
                class="w-5 h-5"
                :class="iLiked ? 'text-red-400' : 'fill-current'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <!-- <button class="btn-primary flex items-center">
              <svg
                class="fill-current w-5 h-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
                <path
                  d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"
                />
              </svg>
              In eigenen Plan kopieren
            </button> -->
          </div>
        </div>
        <FeedDiary :feed="plan" :week="currentWeek"></FeedDiary>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../Layouts/Layout.vue";
import FeedDiary from "../components/FeedDiary.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
export default {
  name: "FeedDetail",
  components: { Layout, FeedDiary },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const feed = ref({});
    const plan = ref(null);
    const currentWeek = ref("");
    const user = ref(JSON.parse(window.localStorage.getItem("user")));

    onMounted(async () => {
      feed.value = await getFeedDiary(route.params.id);
      console.log(feed.value);
      plan.value = JSON.parse(feed.value.diary);
      currentWeek.value = feed.value.week;
    });

    const getFeedDiary = async (feedId) => {
      try {
        const { data } = await window.axios.get(`/feed/diary/${feedId}`);
        return data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const toggleLike = async () => {
      const feedId = route.params.id;
      try {
        await window.axios.post(`/feed/${feedId}/toggleLike`, {
          user: user.value._id,
        });

        if (feed.value.likes && feed.value.likes.includes(user.value._id)) {
          feed.value.likes.splice(feed.value.likes.indexOf(user.value._id), 1);
        } else {
          feed.value.likes.push(user.value._id);
        }
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const iLiked = computed(
      () => feed.value.likes && feed.value.likes.includes(user.value._id)
    );

    return {
      toggleLike,
      feed,
      currentWeek,
      plan,
      iLiked,
    };
  },
};
</script>
