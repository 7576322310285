<template>
  <div class="md:mt-6 md:flex">
    <div class="hidden md:block">
      <div class="border-b py-5 text-lg">&nbsp;</div>
      <div class="border-r">
        <div class="py-5 border-b">
          <div class="min-h-full h-28 flex items-center justify-center">
            <span
              class="px-4 py-1 tracking-wide text-white rounded text-xs transform -rotate-90 bg-primary"
              >{{ $t("breakfast") }}</span
            >
          </div>
        </div>
        <div class="py-5 border-b">
          <div class="min-h-full h-28 flex items-center justify-center">
            <span
              class="px-4 py-1 tracking-wide text-white rounded text-xs transform -rotate-90 bg-primary"
              >{{ $t("lunch") }}</span
            >
          </div>
        </div>
        <div class="py-5 border-b">
          <div class="min-h-full h-28 flex items-center justify-center">
            <span
              class="px-4 py-1 tracking-wide text-white rounded text-xs transform -rotate-90 bg-primary"
              >{{ $t("dinner") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="md:flex md:flex-1">
      <div class="md:flex-1" v-for="(plan, day, dayIndex) in plan" :key="day">
        <h4 class="text-lg tracking-wider border-b py-5 pl-4">
          {{ $t(day) }} {{ dateOfDay(dayIndex) }}
        </h4>
        <div
          v-for="(dish, index) in plan"
          :key="index"
          class="py-4 pl-4 pr-4 md:py-5 border-b md:pl-3 md:pr-0 bg-white"
        >
          <div
            v-if="dish.dish"
            class="rounded-lg p-2 min-h-full h-28 border border-primary shadow-lg text-gray-900 flex flex-col justify-between"
          >
            <span class="text-xl md:text-base">{{ dish.dish.name }}</span>
            <div class="flex items-end">
              <div class="flex items-center">
                <span
                  v-for="category in dish.dish.categories"
                  :title="category.name"
                  :key="category._id"
                  class="h-3 w-3 p-1 rounded-full mr-1"
                  :style="`background: ${category.color}`"
                ></span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="rounded-lg p-2 min-h-full h-28 card-empty flex items-center justify-center"
          >
          </div>
        </div>
      </div>
      <div class="hidden md:block w-3">
        <div class="py-5 text-lg border-b">&nbsp;</div>
        <div class="py-5 border-b">
          <div class="min-h-full h-28">&nbsp;</div>
        </div>
        <div class="py-5 border-b">
          <div class="min-h-full h-28">&nbsp;</div>
        </div>
        <div class="py-5 border-b">
          <div class="min-h-full h-28">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue';
export default {
  name: "FeedDiary",
  props: ['feed', 'week'],
  setup(props) {
    const plan = toRef(props, 'feed')
    const week = toRef(props, 'week')

    const dateOfDay = (index) =>
      window.dayjs().week(week.value).weekday(index).format("DD.MM.");

    return {
      plan,
      dateOfDay,
    };
  },
};
</script>